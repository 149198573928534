import { css } from '@emotion/core'
import { mq } from './Layout.styles'

export const aboutBps = css(
  mq({
    marginTop: [34.5, 35, 32, 52]
  })
)

export const bgBps = css(
  mq({
    backgroundRepeat: ['no-repeat'],
    height: ['auto'],
    backgroundSize: ['cover'],
    backgroundPosition: ['70% 0%', '70% 0%', 'right', 'right']
  })
)

export const H4HeadingBps = css(
  mq({
    width: [328, '100%', 422, 422],
    fontSize: [33.6],
    fontWeight: ['bold'],
    marginBottom: [24], 
    textAlign: ['center', 'center', 'left', 'left']
  })
)

export const H4ContentBps = css(
  mq({
    width: [290, '100%', 504, 504],
    fontSize: [33.6],
    fontWeight: ['bold'],
    marginBottom: [16]
  })
)

export const H4Bps = css(
  mq({
    width: [290, 420, 416, 507]
  })
)

export const PBps = css(
  mq({
    width: [290, '100%', 420, 420],
    marginTop: [30, 16, 16, 16],
    marginBottom: [30]
  })
)

export const ImgHeadingBps = css(
  mq({
    width: [290, 350, 400, 400],
    height: [290, 350, 400, 400],
    marginLeft: ['none', 'none', 'auto', 'auto']
  })
)

export const PHeadingBps = css(
  mq({
    maxWidth: [328, '100%', 416, 416],
    fontSize: [14],
    marginBottom: [56],
    textAlign: ['center', 'center', 'left', 'left']
  })
)

export const titleMoreBps = css(
  mq({
    justifyContent: ['center', 'center', 'flex-end', 'flex-end'],
    display: ['flex'],
    alignItems: ['flex-end']
  })
)

export const PContentBps = css(
  mq({
    width: [290, '100%', 504, 504]
  })
)

export const titleBps = css(
  mq({
    width: ['100%', '100%', 620, 620],
    fontSize: [33.6, 33.6, 47.9, 47.9],
    textAlign: ['center', 'center', 'left', 'left']
  })
)

export const subTitleBps = css(
  mq({
    width: ['100%', '100%', 620, 620],
    textAlign: ['center', 'center', 'left', 'left']
  })
)

export const styledAbout = css`
  ${aboutBps};
`
export const header = css`
  display: flex;
  img {
    ${ImgHeadingBps};
  }
  h4 {
    ${H4HeadingBps};
    color: rgba(0, 0, 0, 0.87);
  }
  p {
    color: rgba(0, 0, 0, 0.6);
    line-height: 1.71;
    ${PHeadingBps};
  } 
`

export const blocContent = css`
  background: #f3f5f5;
`

export const stylePress = css`
  background-color: #9013fe;
`

export const contentPress = css`
  display: flex;
  span {
    font-size: 14px;
    line-height: 1.71;
    color: rgba(255, 255, 255, 0.6);
    margin-bottom: 16px;
  }
  h4 {
    ${H4ContentBps};
    display: grid;
  }
  p {
    ${PContentBps};
    color: #fff;
  }
`

export const styledBox = css`
  span {
    font-size: 14px;
    line-height: 1.71;
    color: rgba(0, 0, 0, 0.6);
    margin-bottom: 16px;
  }
  h4 {
    display: grid;
    color: rgba(0, 0, 0, 0.87);
  }
  p {
    ${PBps};
  }
`

export const listItens = css`
  display: flex;
  align-items: end;
  margin-bottom: 16px;
  img {
    margin-right: 16px;
  }
  span {
    font-size: 15.7px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.53;
    letter-spacing: 0.15px;
    color: rgba(0, 0, 0, 0.87);
    max-width: 280px;
  }
`

export const title = css`
  display: block;
  ${titleBps};
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  margin-bottom: 24px;
  span {
    background-color: #f8e71c;
    color: #9013fe;
    padding: 0 4px;
    font-weight: bold;
  }
`

export const subTitle = css`
  ${subTitleBps};
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: rgba(255, 255, 255, 0.7);
`

export const titleMore= css`
  text-align: right;
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  cursor: pointer;
  svg {
    transition: all 0.2s ease-in-out;
    :hover {
      transform: rotate(360deg);
    }
  }
`