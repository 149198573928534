import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
/* import Img from "gatsby-image" */
import { graphql, Link }  from 'gatsby'
import { Box } from '@material-ui/core'

import Layout from '../components/Layout'
import Seo from '../components/Seo'
import Row from '../components/Row'
import Column from '../components/Column'
import Div from './../components/Div'
import ContainedButton from '../components/ContainedButton'
import ArrowR from '@material-ui/icons/ArrowForward'
import AddCircle from '@material-ui/icons/AddCircleOutline'
import H4 from '../components/H4'
import Paragraph from '../components/Paragraph'
import VouPraNuvem from '../images/vou-pra-nuvem/vou-pra-nuvem.png'
import BgVouPraNuvem from '../images/vou-pra-nuvem/bg2.png'
import ScrollAnim from 'rc-scroll-anim'

import { mq, Container } from '../components/styles/Layout.styles'
import {
  styledAbout,
  header,
  stylePress,
  contentPress,
  ImgHeadingBps,
  listItens,
  title,
  subTitle,
  titleMore,
  titleMoreBps,
  bgBps
} from '../components/styles/VouPraNuvem.styles'
import css from '@emotion/css'
import { 
  termosBoxWhite 
} from '../components/styles/Contact.styles'
import StorageForm from '../components/VouPraNuvemFormWhite'
import { validateRequiredEmail } from '../utils/validations'
const fields = [
  {
    label: 'Nome',
    name: 'your-name', 
    type: 'text',
    required: true
  },
  {
    label: 'Telefone',
    name: 'your-phone',
    type: 'text',
    required: true,
    mask: ['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
  },
  {
    label: 'Email',
    name: 'your-email',
    type: 'email',
    required: true,
    validate: validateRequiredEmail,
  },
  {
    label: 'Empresa',
    name: 'your-company',
    type: 'text',
    required: true
  },
  {
    label: (
      <span css={termosBoxWhite}>
        Ao continuar, indica que aceita os
        {" "}
        <Link to="/termos-de-servico" style={{ textDecoration: 'underline' }}>
          Termos de Serviço
        </Link>
        {" "}
        e a nossa
        {" "}
        <Link to="/politica-de-privacidade" style={{ textDecoration: 'underline' }}>
          Política de Privacidade.
        </Link>
      </span>
    ),
    name: 'your-consent',
    type: 'checkbox',
    required: true
  },
]

export const PageTemplate = ({
  data
}) => {
  console.log(data)
  return (
    <Fragment>
      <section>
        <div
          css={bgBps} 
          style={{ 
            backgroundImage: `url(${BgVouPraNuvem})`
          }}
        >
          <Div
            maxWidth={['100%', 1040]}
            style={{
              margin: 'auto'
            }}
          >
            <Row
              flexDirection={['column', 'column', 'row', 'row']}
              mt={[50, 105]}
              mx={[0, 0, -24, -24]}
            >
              <Column
                width={[1]}
              >
                <Div
                  css={title}
                >
                  Você ganhou <span>1 hora</span> de consultoria com nossos especialistas
                </Div>
                <Div
                  css={subTitle}
                >
                  Cadastre-se agora e descubra como a gestão de TI da sua empresa pode alavancar negócios com soluções personalizadas!
                </Div>
              </Column>
            </Row>
            <Row
              flexDirection={['column', 'column', 'row', 'row']}
              mt={[40, 75]}
              pb={[50, 100]}
              mx={[0, 0, -24, -24]}
            >
              <Column
                width={[1]}
              >
                <Div
                  alignItems={['center', 'center', 'flex-start', 'flex-start']}
                  style={{
                    display: 'flex',
                    flexDirection: 'column'
                  }}
                >
                  <StorageForm
                    fields={fields}
                    formId={4209}
                    submitText='Quero minha consultoria grátis'
                  />
                </Div>
              </Column>
              <Column
                width={[1]}
                mt={[50, 50, 0, 0]}
                css={titleMoreBps}
              >
                <ScrollAnim.Link
                  to='quero-saber-mais'
                  offsetTop='120'
                >
                  <div css={titleMore} >
                    <AddCircle 
                      style={{
                        color: '#ffffff',
                        marginRight: 8
                      }}
                    />
                    Quero saber mais
                  </div>
                </ScrollAnim.Link>
              </Column>
            </Row>
          </Div>
        </div>
      </section>
      <section css={styledAbout} id='quero-saber-mais'>
        <Container>
          <Row
            flexDirection={['column', 'column', 'row', 'row']}
            css={header}
            mt={[100]}
            pb={60}
          >
            <Column
              order={[2, 2, 1, 1]}
              width={[1, 1, 1/2, 1/2]}
              justifyContent={['center', 'center', 'left', 'left']}
              display={['flex']}
              alignItems={['center', 'end']}
            >
              <img src={VouPraNuvem} css={ImgHeadingBps} />
            </Column>
            <Column
              order={[1, 1, 2, 2]}
              ml={[0, 0, 37, 37]}
              width={[1, 1, 1/2, 1/2]}
              mt={[0, 0, 65, 65]}
            >
              <H4>Dê o primeiro passo para a transformação do seu negócio</H4>
              <Paragraph>Se você busca alta disponibilidade nos serviços, segurança da informação, alocação de servidores e otimização da gestão de TI, está na hora de ter soluções sob medida para o seu negócio. Fale com um especialista e descubra o serviço ideal da HostDime Brasil para a sua empresa.</Paragraph>
            </Column>
          </Row>
          <Div
            maxWidth={['100%', 1040]}
            alignItems={['center']}
            style={{
              margin: 'auto'
            }}
          >
            <Row
              mb={[54, 102]}
              flexDirection={['column', 'column', 'row', 'row']}
            >
              <Column
                width={[1, 1, 1/3, 1/3]}
              >
                <div css={listItens}>
                  <ArrowR 
                    style={{
                      color: 'rgba(0, 0, 0, 0.54)',
                      marginRight: 16
                    }}
                  />
                  <span>Um ou mais servidores exclusivos para a sua empresa</span>
                </div>
                <div css={listItens}>
                  <ArrowR 
                    style={{
                      color: 'rgba(0, 0, 0, 0.54)',
                      marginRight: 16
                    }}
                  />
                  <span>Portal de gerenciamento dos servidores intuitivo</span>
                </div>
                <div css={listItens}>
                  <ArrowR 
                    style={{
                      color: 'rgba(0, 0, 0, 0.54)',
                      marginRight: 16
                    }}
                  />
                  <span>Inspeção de segurança em 5 etapas</span>
                </div>
              </Column>
              <Column
                width={[1, 1, 1/3, 1/3]}
              >
                <div css={listItens}>
                  <ArrowR 
                    style={{
                      color: 'rgba(0, 0, 0, 0.54)',
                      marginRight: 16
                    }}
                  />
                  <span>Flexibilidade na escolha do hardware</span>
                </div>
                <div css={listItens}>
                  <ArrowR 
                    style={{
                      color: 'rgba(0, 0, 0, 0.54)',
                      marginRight: 16
                    }}
                  />
                  <span>IPMI gerencie seu hardware remotamente (BIOS)</span>
                </div>
              </Column>
              <Column
                width={[1, 1, 1/3, 1/3]}
              >
                <div css={listItens}>
                  <ArrowR 
                    style={{
                      color: 'rgba(0, 0, 0, 0.54)',
                      marginRight: 16
                    }}
                  />
                  <span>Monitoramento proativo 24x7x365</span>
                </div>
                <div css={listItens}>
                  <ArrowR 
                    style={{
                      color: 'rgba(0, 0, 0, 0.54)',
                      marginRight: 16
                    }}
                  />
                  <span>Monitoramento em tempo real</span>
                </div>
              </Column>
            </Row>
          </Div>
        </Container>
      </section>
      <section>
        <Box 
          css={stylePress}
          style={{
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          <Container
            css={css(
              mq({
                paddingLeft: [35, 90, 0, 0],
                paddingRight: [35, 90, 0, 0],
                margin: [0],
                maxWidth: ['100%']
              })
            )}
          >
            <Row
              flexDirection={['column', 'column', 'row', 'row']}
              css={contentPress}
              pt={[50, 50, 80, 97]}
              pb={[54, 54, 100, 117]}
            >
              <Column
                width={[1, 1, 1/2, 1/2]}
              >
                <H4>Somos estrutura e parceria para sua gestão de TI</H4>
                <Paragraph>As soluções em data center da HostDime Brasil oferecem segurança, inovação e disponibilidade para sua empresa. Confira as soluções globais e personalizadas da HostDime para o seu negócio aqui.</Paragraph>
              </Column>
              <Column
                mt={[50, 50, 0, 0]}
                width={[1, 1, 1/2, 1/2]}
                ml={[0, 0, 90, 90]}
              >
                <Box
                  mt={[0, 0, 6, 6]}
                  ml={[0, 0, 0, 10]}
                  display={'flex'}
                  flexDirection={'column'}
                >
                  <div
                    style={{
                      marginBottom: 16
                    }}
                  >
                    <ContainedButton
                      type='btnContainedSmall'
                      Link='/nossos-produtos'
                    >
                      NOSSOS PRODUTOS
                    </ContainedButton>
                  </div>
                  <ContainedButton
                    type='btnContainedWhiteSmall'
                    Link='/estrutura'
                  >
                    NOSSA ESTRUTURA
                  </ContainedButton>
                </Box>
              </Column>
            </Row>
          </Container>
        </Box>
      </section>
    </Fragment>
  )
}

PageTemplate.propTypes = {
  data: PropTypes.object
}

const StaticPage = ({data}) => { 
  return (
    <Layout>
      <Seo 
        title='Vou pra nuvem | HostDime Brasil'
        description='Você ganhou 1 hora de consultoria com nossos especialistas'
        keywords={['teste', 'vida']}
      />
      <PageTemplate
        title='Vou pra nuvem'
        data={data}
      />
    </Layout>
  )
}

StaticPage.propTypes = {
  data: PropTypes.object
}

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "vou-pra-nuvem/vou-pra-nuvem.png" }) {
      childImageSharp {
        fluid(maxWidth: 390) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default StaticPage